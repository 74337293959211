@import '../../app/assets/styles/mixins';
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__form {
    display: flex;
    gap: 14px;
    flex-direction: column;
    width: 100%;
  }

  &__user {
    width: 96px;
    height: 96px;
    border-radius: 24px;
    background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px auto 0;
    user-select: none;
    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
    img {
      width: 48px;
      height: 48px;
      user-select: none;
      pointer-events: none;
      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__title {
    color: var(--Content-White, #fff);
    text-align: center;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-top: 32px;
    user-select: none;
  }

  &__subtitle {
    color: var(--Content-Secondary, #ccc);
    text-align: center;

    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 8px;
    max-width: 300px;
    user-select: none;
  }
  &__notion {
    display: block;

    margin-top: 24px;
    color: var(--Content-Tertiary, #a3a3a3);
    text-align: center;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-wrap: wrap;
    a {
      display: inline-flex;
      white-space: nowrap;
      color: var(--Content-Primary, #ebebeb);
      margin-left: 5px;

      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      transition: all 0.3s ease;
      &:hover {
        transition: all 0.3s ease;
        opacity: 0.7;
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  &__otp {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 24px;
    width: 100%;
    align-items: center;
    > div {
      gap: 8px;
    }

    button {
      width: 100%;
    }

    input {
      border-radius: 12px;
      background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
      min-width: 52px;
      height: 62px;
      color: var(--Content-Primary, #ebebeb);
      text-align: center;

      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      border: 1.5px solid rgba(235, 235, 235, 0.2);
      outline: none;

      &:focus::placeholder {
        color: transparent;
      }

      &::placeholder {
        font-size: 16px;
      }
      @media (max-width: 414px) {
        min-width: 40px;
        height: 60px;
      }
    }

    &--error {
      border: 1.5px solid var(--Border-Negative, #ed603e) !important;
    }
  }
}
