@import '../../app/assets/styles/mixins';

.root {

}

.popup {
  &__block {
    position: fixed;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    background: #0c0c0c;
    display: flex;
    max-width: 500px;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
      max-width: 100%;
      background: #0c0c0c;
    }
  }

  &__bg {
    position: fixed;
    top: -70%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 800px;
    width: 100vw;
    height: 140%;
    border-radius: 50%;
    z-index: -1;
    background: rgba(129, 127, 183, 0.1);
    filter: blur(5rem);
  }

  &__title {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    user-select: none;
  }

  &__subtitle {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
    user-select: none;
  }

  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    user-select: none;
    outline: none;
  }
}

.backdrop {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
}
